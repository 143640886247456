import { FormattedMessage } from 'react-intl';
import IconUser from 'assets/icons/Profile.png'
import Contributions from 'assets/icons/Contributions.png'

// ==============================|| MENU ITEMS - Image PAGE ||============================== //

const icons = {
    IconUser,
    Contributions
};
const profilePage = {
    id: 'profile-page',
    title: <FormattedMessage id="profile-page-title" />,
    caption: <FormattedMessage id="profile-page-caption" />,
    icon: icons.IconUser,
    type: 'group',
    children: [
        {
            id: 'profile',
            title: <FormattedMessage id="Profile" />,
            type: 'collapse',
            icon: icons.IconUser,
            children: [
                {
                    id: 'profile-page',
                    title: <FormattedMessage id="My Profile" />,
                    type: 'item',
                    url: '/profile/observations',
                    icon: icons.IconUser,
                    target: false,
                },
                {
                    id: 'reporting-module',
                    title: <FormattedMessage id="My Contributions" />,
                    type: 'item',
                    url: '/my-contributions',
                    icon: icons.Contributions,
                    target: false
                }
            ]
        }
    ]
};

export default profilePage;
