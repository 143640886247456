import { FormattedMessage } from 'react-intl';

// assets
import ManageSearchOutlinedIcon from 'assets/icons/Browse.png'
import Observations from 'assets/icons/Observations.png'
import Collections from 'assets/icons/Collections.png'
import Taxonomies from 'assets/icons/Taxa.png'
import Users from 'assets/icons/Profiles.png'
import Books from 'assets/icons/Books.png'

// ==============================|| MENU ITEMS - EXPLORE PAGE ||============================== //

const icons = {
    ManageSearchOutlinedIcon,
    Observations,
    Collections,
    Taxonomies,
    Users,
    Books
};
const ExplorePage = {
    id: 'explore-page',
    title: <FormattedMessage id="explore-page-title" />,
    caption: <FormattedMessage id="explore-page-caption" />,
    icon: icons.ManageSearchOutlinedIcon,
    type: 'group',
    children: [
        {
            id: 'browse',
            title: <FormattedMessage id="Browse" />,
            type: 'collapse',
            icon: icons.ManageSearchOutlinedIcon,
            children: [
                {
                    id: 'browse-observations',
                    title: <FormattedMessage id="Observations" />,
                    type: 'item',
                    url: '/browse/observations',
                    icon: icons.Observations,
                    target: false,
                },
                {
                    id: 'browse-collections',
                    title: <FormattedMessage id="Collections" />,
                    type: 'item',
                    url: '/browse/collections',
                    icon: icons.Collections,
                    target: false,
                },
                {
                    id: 'browse-taxon',
                    title: <FormattedMessage id="Taxa" />,
                    type: 'item',
                    url: '/browse/taxonomies',
                    icon: icons.Taxonomies,
                    target: false,
                },
                {
                    id: 'browse-etymologies',
                    title: <FormattedMessage id="Etymologies" />,
                    type: 'item',
                    url: '/browse/etymologies',
                    icon: icons.Taxonomies,
                    target: false,
                },
                {
                    id: 'browse-users',
                    title: <FormattedMessage id="Users" />,
                    type: 'item',
                    url: '/browse/users',
                    icon: icons.Users,
                    target: false,
                },
                // {
                //     id: 'browse-localities',
                //     title: <FormattedMessage id="Localities" />,
                //     type: 'item',
                //     url: '/browse/localities',
                //     target: false,
                // },
                {
                    id: 'browse-books',
                    title: <FormattedMessage id="Books" />,
                    type: 'item',
                    url: '/browse/books',
                    icon: icons.Books,
                    target: false,
                }
            ]
        }
    ]
};

export default ExplorePage;
