import { memo, useMemo, useState, useRef, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {Box, Divider, Drawer, IconButton, Stack, useMediaQuery} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
// import MenuCard from './MenuCard';
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';
// import Chip from 'ui-component/extended/Chip';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import UniversalSearch from 'ui-component/autocomplete/UniversalSearch';
import SearchIcon from 'assets/icons/Search.png';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { layout, drawerType } = useConfig();

    const [itemFocus, setItemFocus] = useState(false);

    const [isHovered, setIsHovered] = useState(false);

    let openTimer = useRef(null);
    let closeTimer = useRef(null);

    const handleMouseEnter = () => {
        setIsHovered(true);
        clearTimeout(openTimer.current);
        clearTimeout(closeTimer.current); // Clear close timer when mouse enters
        if (!itemFocus) {
            // Only open drawer if no item is focused
            openTimer.current = setTimeout(() => {
                dispatch(openDrawer(true));
            }, 1500); // delay opening by 1500ms
        }
    };

    // // Disables the open drawer event when over a group item
    // useEffect(() => {
    //     if (itemFocus === true) {
    //         setIsHovered(false);
    //         clearTimeout(openTimer.current);  // Clear open timer when mouse leaves
    //         clearTimeout(closeTimer.current);
    //         closeTimer.current = setTimeout(() => {
    //             dispatch(openDrawer(false));
    //         }, 500); // delay closing by 500ms
    //     }
    // }, [itemFocus]);

    const handleMouseLeave = () => {
        setIsHovered(false);
        clearTimeout(openTimer.current); // Clear open timer when mouse leaves
        clearTimeout(closeTimer.current);
        setItemFocus(false); // Clear item focus when mouse leaves
        closeTimer.current = setTimeout(() => {
            dispatch(openDrawer(false));
        }, 500); // delay closing by 500ms
    };

    // Clear timeouts on unmount
    useEffect(() => {
        return () => {
            clearTimeout(openTimer.current);
            clearTimeout(closeTimer.current);
        };
    }, []);

    const logo = useMemo(
        () => (
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawerContent = (
        <>
            <MenuList drawerOpenEnter={handleMouseEnter} drawerCloseLeave={handleMouseLeave} />
        </>
    );

    const drawerSX = {
        paddingLeft: drawerOpen ? '16px' : 0,
        paddingRight: drawerOpen ? '16px' : 0,
        marginTop: drawerOpen ? 0 : '20px'
    };

    const drawer = useMemo(
        () => (
            <>
                {matchDownMd ? (
                    <Box sx={drawerSX}>{drawerContent}</Box>
                ) : (
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            ...drawerSX
                        }}
                    >
                        {drawerContent}
                    </PerfectScrollbar>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, drawerOpen, drawerType]
    );

    const [searchResults, setSearchResults] = useState([]);

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: matchUpMd ? drawerWidth : 'auto'
            }}
            aria-label="mailbox folders"
            onMouseEnter={matchUpMd ? handleMouseEnter : undefined} // Conditionally apply onMouseEnter
            onMouseLeave={matchUpMd ? handleMouseLeave : undefined} // Conditionally apply onMouseLeave
        >
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen || isHovered}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {matchDownMd && logo}

                    <Box px={2}>
                        <UniversalSearch searchResults={searchResults} setSearchResults={setSearchResults} />
                    </Box>

                    {drawer}
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    {logo}

                    {/* Conditionally render the UniversalSearch or SearchIcon */}
                    {drawerOpen ? (
                        <Box px={2} sx={{ height: '40px' }}>
                            <UniversalSearch searchResults={searchResults} setSearchResults={setSearchResults} />
                        </Box>
                    ) : (
                        <Box sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}>
                            <IconButton
                                onClick={() => dispatch(openDrawer(true))}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    }
                                }}
                            >
                                <Box
                                    component="img"
                                    src={SearchIcon}
                                    alt="Search"
                                    sx={{ width: 35, height: 35, objectFit: 'contain' }}
                                />
                            </IconButton>
                            <Divider sx={{ width: '100%', mt: '5px' }} />
                        </Box>
                    )}
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
