import { FormattedMessage } from 'react-intl';

// assets
import HandymanOutlinedIcon from 'assets/icons/Tools.png';
import API from 'assets/icons/API.png';
import SiteStatus from 'assets/icons/Site_status.png';
import Updates from 'assets/icons/Update.png'
import {environment} from "config";

// ==============================|| MENU ITEMS - Admin PAGE ||============================== //

const icons = {
    HandymanOutlinedIcon,
    API,
    SiteStatus,
    Updates
};

const ToolsPage = {
    id: 'tools-page',
    title: <FormattedMessage id="tools-page-title" />,
    caption: <FormattedMessage id="tools-page-caption" />,
    icon: icons.HandymanOutlinedIcon,
    type: 'group',
    children: [
        {
            id: 'tools',
            title: <FormattedMessage id="Tools" />,
            type: 'collapse',
            icon: icons.HandymanOutlinedIcon,
            children: [
                {
                    id: 'public-api',
                    title: <FormattedMessage id="Public API"/>,
                    type: 'item',
                    url: `${environment.API_URL}/api`,
                    icon: icons.API,
                    target: true,
                },
                {
                    id: 'site-status',
                    title: <FormattedMessage id="Site Status"/>,
                    type: 'item',
                    url: 'https://stats.uptimerobot.com/d5omu5ETY4',
                    icon: icons.SiteStatus,
                    target: true,
                },
                {
                    id: 'site-updates',
                    title: <FormattedMessage id="Updates"/>,
                    type: 'item',
                    url: '/updates',
                    icon: icons.Updates,
                    target: false,
                }
            ]
        }
    ]
};

export default ToolsPage;
